import React from "react";
import CountUp from "react-countup";
import { MdiCheckBold } from "../../Uikits/Icons";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllFormations } from "../../Utils/Queries/formations";

const pricing = [
  {
    pricing: 500,
    name: "Pro",
  },
  {
    pricing: 200,
    name: "Boost",
  },
  {
    pricing: 1000,
    name: "Vip",
  },
];

function HomePricing() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allFormations2"],
    queryFn: getAllFormations,
    initialData: [],
  });

  if (isLoading) return "...";
  if (!data.data) return;
  if (data.data && data.data.length == 0) return;
  return (
    <div className="homePricing">
      <h2>Nos formations</h2>
      <div className="flex f-wrap">
        {data.data.map((item, i) => (
          <article
            className="planCard flex f-column"
            key={"result stat nb" + i}
          >
            <section className="pc-top">
              <b>{item.name} </b>
              <p className="flex ai-c">
                $<h3> {item.price} </h3>
                <sub>/{item.time}</sub>
              </p>
            </section>

            <ul className="pc-bottom flex f-column">
              {item.details.map((car, j) => (
                <li className="flex ai-c" key={"plan car nb" + j}>
                  <MdiCheckBold />
                  <p>{car} </p>
                </li>
              ))}
            </ul>

            <Link to={"/"} className="btnStyle3">
              Souscrire
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
}

export default HomePricing;
