import "./Styles/index.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import ScrollToTop from "./Components/home/ScrollToTop";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import ManageFormations from "./Pages/ManageFormations";
import Modal from "./Uikits/Modal";
import ManageReview from "./Pages/ManageReview";
import ManageProduct from "./Pages/ManageProduct";
import Faq from "./Pages/Faq";
import ProductDetails from "./Pages/ProductsInfo";
import LeadPage from "./Pages/LeadPage";
import ManageLeadPageContent from "./Pages/ManageLeadPageContent";
import "./Styles/animate.min.css";
import AllTestimonials from "./Pages/AllTestimonials";
import Confidentiality from "./Pages/Confidentiality";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Modal />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:productId" element={<ProductDetails />} />
        <Route path="/testimonials" element={<AllTestimonials />} />
        <Route path="/confidentiality" element={<Confidentiality />} />
        <Route path="/lead" element={<LeadPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/manage-formations" element={<ManageFormations />} />
        <Route path="/manage-reviews" element={<ManageReview />} />
        <Route path="/manage-products" element={<ManageProduct />} />
        <Route
          path="/manage-leadContents"
          element={<ManageLeadPageContent />}
        />
      </Routes>
    </div>
  );
}

export default App;
