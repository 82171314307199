import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import { useQuery } from "@tanstack/react-query";
import useModalStore from "../Stores/Modal";
import axiosInstance from "../Utils/Axios";
import { getAllLeadPageContent } from "../Utils/Queries/leadPage";
import { AddorUpdateLPParagraph } from "../Components/leadPageContents/paragraph";
import { AddorUpdateLPVideo } from "../Components/leadPageContents/video";
import { successToast } from "../Utils/Toast";
import { AddLPReview } from "../Components/leadPageContents/reviews";
import { AddorUpdateLPGallery } from "../Components/leadPageContents/gallery";
import { BackgroundButton, BorderButton } from "../Uikits/Button";
import { AddorUpdateLPButton } from "../Components/leadPageContents/button";
import { SectionLoader } from "../Uikits/Others";

function ManageLeadPageContent() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allLeadPageContent"],
    queryFn: getAllLeadPageContent,
  });

  const showModal = useModalStore((state) => state.showModal);

  function upOrder(contentId) {
    axiosInstance
      .put("/leadPage/up-order/" + contentId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function downOrder(contentId) {
    axiosInstance
      .put("/leadPage/down-order/" + contentId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Ordre modifier!");
      });
  }

  function deleteContent(contentId) {
    axiosInstance
      .delete("/leadPage/delete/" + contentId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Section supprimer !");
      });
  }
  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestions de la page Lead</h1>
      <div className="flex jc-se">
        <BorderButton
          text="Nouveau Paragraphe"
          onClick={() =>
            showModal(<AddorUpdateLPParagraph refetch={refetch} />)
          }
        />
        <BorderButton
          text="Nouvelle gallerie d'image"
          onClick={() => showModal(<AddorUpdateLPGallery refetch={refetch} />)}
        />
        <BorderButton
          text="Nouvelle video"
          onClick={() => showModal(<AddorUpdateLPVideo refetch={refetch} />)}
        />
        <BorderButton
          text="Nouvelle section Temoignage"
          onClick={() => showModal(<AddLPReview refetch={refetch} />)}
        />
        <BorderButton
          text="Nouveau bouton"
          onClick={() => showModal(<AddorUpdateLPButton refetch={refetch} />)}
        />
      </div>
      {data.data.length > 0 ? (
        <section className="dm-dataList2">
          {data.data.length > 0 ? (
            data.data
              .sort((a, b) => a.order - b.order)
              .map((item, i) => (
                <article key={"formations nb " + i}>
                  <h2>Section: {item.order} </h2>
                  <br />
                  {item.contentType == "paragraph" && <p>{item.paragraph} </p>}
                  {item.contentType == "gallery" && (
                    <div
                      className=""
                      style={{
                        columns: 2,
                      }}
                    >
                      {item.gallery.map((media, j) => (
                        <img
                          src={media.url}
                          alt=""
                          style={{
                            width: "100%",
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {item.contentType == "video" && (
                    <video src={item.video.url} controls></video>
                  )}

                  {item.contentType == "reviews" && (
                    <p>Section Temoignage Afficher </p>
                  )}

                  {item.contentType == "button" && (
                    <BackgroundButton
                      text={item.buttonText}
                      isLink={true}
                      target="_blank"
                      link={item.buttonLink}
                    />
                  )}

                  <div className="flex" style={{ gap: "0 15px" }}>
                    <button
                      className="deleteBtn"
                      onClick={() => deleteContent(item._id)}
                    >
                      Supprimer la section
                    </button>

                    {item.order > 1 && (
                      <button
                        className="updateBtn"
                        onClick={() => upOrder(item._id)}
                      >
                        Remonter d'une position
                      </button>
                    )}

                    {item.order != data.data.length && (
                      <button
                        className="updateBtn"
                        onClick={() => downOrder(item._id)}
                      >
                        Descendre d'une position
                      </button>
                    )}
                  </div>
                </article>
              ))
          ) : (
            <p>Aucun avis disponible</p>
          )}
        </section>
      ) : (
        <p>Aucune section disponible</p>
      )}
    </div>
  );
}

export default needAuth(ManageLeadPageContent);
