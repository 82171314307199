import {
  autorAudioOrVideoField,
  autorName,
  autorPicture,
  autorText,
  emailField,
  formationDetails,
  formationName,
  formationPrice,
  formationTime,
  lpButtonLink,
  lpButtontext,
  lpGallery,
  lpParagraph,
  lpVideo,
  messageField,
  nameField,
  passwordField,
  productCompetitorLink,
  productCompetitorPubLink,
  productDesc,
  productName,
  productPicture1,
  productPicture2,
  productPicture3,
  productPicture4,
  productPictures,
  productPrice,
  productSupplierLink,
  productSupplierPrice,
  subjectField,
} from "./fields";

export const loginFields = [emailField, passwordField];

export const contactFields = [
  nameField,
  emailField,
  subjectField,
  messageField,
];

export const updateAccountFields = [nameField, emailField, passwordField];

export const formationsFields = [
  formationName,
  formationPrice,
  formationTime,
  formationDetails,
];

export const reviewsFields = [
  autorName,
  autorText,
  autorPicture,
  autorAudioOrVideoField,
];

export const productsFields = [
  productName,
  productPrice,
  productDesc,
  productPictures,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
];

export const upProductsFields = [
  productName,
  productPrice,
  productDesc,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
];

export const addLPParagraphFields = [lpParagraph];

export const addLPButtonFields = [lpButtontext, lpButtonLink];

export const addLPVideoFields = [lpVideo];

export const addLPVGalleryFields = [lpGallery];
