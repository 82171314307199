import React from "react";
import {
  FormikMultipleText,
  FormikSharedTextInput,
  FormikTextArea,
} from "./simpleText";
import { FormikPasswordInput } from "./password";
import { FormikCheckboxInput, FormikRadioInput } from "./choices";
import { ErrorMessage } from "formik";
import { FormikFileInput, FormikFilesInput } from "./file";
import { FormikSelect } from "./select";

function FormFieldProvider(props) {
  const { fieldType } = props;
  const sharedType = ["email", "text", "address", "number", "date", "time"];
  if (sharedType.includes(fieldType))
    return <FormikSharedTextInput {...props} />;
  else if (fieldType == "password") return <FormikPasswordInput {...props} />;
  else if (fieldType == "radio") return <FormikRadioInput {...props} />;
  else if (fieldType == "checkbox") return <FormikCheckboxInput {...props} />;
  else if (fieldType == "file") return <FormikFileInput {...props} />;
  else if (fieldType == "files") return <FormikFilesInput {...props} />;
  else if (fieldType == "textarea") return <FormikTextArea {...props} />;
  else if (fieldType == "select") return <FormikSelect {...props} />;
  else if (fieldType == "multipleText")
    return <FormikMultipleText {...props} />;

  // multipleText
  return <></>;
}

export function FormikErrorMessage({ name }) {
  return (
    <>
      <span className="errorMessageOnField">
        <ErrorMessage name={name} render={(error) => error + "*"} />
      </span>
    </>
  );
}
export default FormFieldProvider;
