import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import { useQuery } from "@tanstack/react-query";

import useModalStore from "../Stores/Modal";
import axiosInstance from "../Utils/Axios";
import { getAllReviews } from "../Utils/Queries/reviews";
import { AddorUpdateReviewForm } from "../Components/reviews/manage";
import { successToast } from "../Utils/Toast";
import { BorderButton } from "../Uikits/Button";
import { SectionLoader } from "../Uikits/Others";

function ManageReview() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allReviews"],
    queryFn: getAllReviews,
  });
  const showModal = useModalStore((state) => state.showModal);

  function deleteFormation(formationId) {
    axiosInstance
      .delete("/review/delete/" + formationId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Avis supprimer !");
      });
  }
  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestions des Avis</h1>
      <BorderButton
        text="Nouveau temoignage"
        onClick={() => showModal(<AddorUpdateReviewForm refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"formations nb " + i}>
              <img src={item.autorPicture.url} alt="" />
              <p>
                <strong>Nom du client: </strong>
                {item.autorName}
              </p>
              <p>
                <strong>Texte du client: </strong>
                {item.text}
              </p>
              <p>
                <strong>Audio/Video: </strong>
                {item.audioOrVideo.type == "audio" && (
                  <audio src={item.audioOrVideo.url} controls></audio>
                )}
                {item.audioOrVideo.type == "video" && (
                  <video controls src={item.audioOrVideo.url}></video>
                )}
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateReviewForm review={item} refetch={refetch} />
                    )
                  }
                >
                  Modifier
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => deleteFormation(item._id)}
                >
                  Supprimer
                </button>
              </div>
            </article>
          ))
        ) : (
          <p>Aucun avis disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageReview);
