import React from "react";
import { Link, NavLink } from "react-router-dom";
import useAuthStore from "../Stores/Auth";

function Sidebar() {
  const logout = useAuthStore((state) => state.logout);
  const links = [
    { to: "/dashboard", label: "Accueil" },
    { to: "/manage-formations", label: "Formations" },
    { to: "/manage-reviews", label: "Témoignage" },
    { to: "/manage-products", label: "Produits" },
    { to: "/manage-leadContents", label: "Page lead" },
  ];
  return (
    <aside className="sidebar">
      <section className="sidebar-logo">
        <img src="/images/logoWhite.png" alt="" />
      </section>
      <section className="sidebar-links">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            {link.label}
          </NavLink>
        ))}
      </section>

      <button onClick={logout}>Se deconnecter</button>
    </aside>
  );
}

export default Sidebar;
