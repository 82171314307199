import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllLeadPageContent } from "../Utils/Queries/leadPage";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import HomeTestimonial from "../Components/home/Testimonial";
import { BackgroundButton } from "../Uikits/Button";
import { SectionLoader } from "../Uikits/Others";

function LeadPage() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allLeadPageContent"],
    queryFn: getAllLeadPageContent,
  });

  if (isLoading) return <SectionLoader />;
  else if (data.data.length == 0) return <p>Aucun contenue a afficher</p>;
  return (
    <div className="leadPage">
      <section className="flex f-wrap">
        {data.data
          .sort((a, b) => a.order - b.order)
          .map((item, i) => (
            <React.Fragment key={"page lead cont nb" + i}>
              {item.contentType == "paragraph" && (
                <div className="lp-paragraph">
                  <p>{item.paragraph} </p>
                </div>
              )}
              {item.contentType == "gallery" && (
                <div className="lp-gallery">
                  {item.gallery.map((media, j) => (
                    <img src={media.url} alt="" />
                  ))}
                </div>
              )}
              {item.contentType == "video" && (
                <div className="lp-video">
                  <video src={item.video.url} controls></video>
                </div>
              )}

              {item.contentType == "reviews" && (
                <div className="lp-reviews">
                  <HomeTestimonial />
                </div>
              )}
              {item.contentType == "button" && (
                <div className="lp-button">
                  <BackgroundButton
                    text={item.buttonText}
                    isLink={true}
                    target="_blank"
                    link={item.buttonLink}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        {/* {JSON.stringify(data.data)} */}
      </section>
    </div>
  );
}

export default noNeedAuth(LeadPage);
