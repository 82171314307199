import * as Yup from "yup";
import { allTimes } from "./fields";

const getVideoDuration = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = () => {
      resolve(0);
    };
  });
};

const canadianPhoneRegex = /^(\+1|01)\d{10}$/;
let name = Yup.string().required("Champs requis");
let subject = Yup.string().required("Champs requis");
let message = Yup.string().required("Champs requis");

let description = Yup.string().required("Champs requis");
let supplierLink = Yup.string().required("Champs requis");
let competitorLink = Yup.string().required("Champs requis");
let supplierPrice = Yup.string().required("Champs requis");
let competitorPubLink = Yup.string().required("Champs requis");

let pictures = Yup.array()
  .min(4, "Au moins 4 éléments.")
  .max(4, "Maximum 4 éléments.")
  .required("Champs requis");
let contentType = Yup.string().required("Champs requis");
let paragraph = Yup.string().required("Champs requis");
let buttonText = Yup.string().required("Champs requis");
let buttonLink = Yup.string().required("Champs requis");
let gallery = Yup.array()
  .min(1, "Au moins un élément.")
  .required("Champs requis");
let video = Yup.mixed().required("Champs requis");

let autorName = Yup.string().required("Champs requis");
let text = Yup.string().required("Champs requis");
let autorPicture = Yup.mixed().required("Champs requis");
let audioOrVideo = Yup.mixed().required("Champs requis");

let email = Yup.string().email("Email invalide");
let price = Yup.number().min(1, "Minimum 1 euros").required("Champs requis");
let time = Yup.string()
  .oneOf(allTimes, "Temps incorrect")
  .required("Champs requis");
let details = Yup.array()
  .min(1, "Au moins un élément.")
  .required("Champs requis");

let phoneNumber = Yup.string().matches(
  canadianPhoneRegex,
  "Le numéro de téléphone doit être un numéro canadien valide"
);

// let gender = Yup.string().oneOf(
//   Object.values(GenderEnum),
//   "Le genre selectionner est incorrect"
// );

let media = Yup.mixed()
  .test("fileType", "Only images and videos are allowed", (value) => {
    return value
      ? value.type.startsWith("image/") || value.type.startsWith("video/")
      : false;
  })
  .test(
    "fileDuration",
    "Video duration must not exceed 1 minute",
    async (value) => {
      if (value && value.type.startsWith("video/")) {
        const duration = await getVideoDuration(value);
        return duration <= 60;
      }
      return true;
    }
  );

export const LoginValidation = () => {
  return Yup.object().shape({
    email: email.required("Champs requis"),
    password: Yup.string().required("Champs requis"),
  });
};

export const ContactValidation = () => {
  return Yup.object().shape({
    name,
    subject,
    message,
    email: email.required("Champs requis"),
  });
};

export const NewFormationValidation = () => {
  return Yup.object().shape({
    name,
    price,
    time,
    details,
  });
};

export const ProductValidation = () => {
  return Yup.object().shape({
    name,
    price,
    pictures,
    description,
    supplierLink,
    competitorLink,
    supplierPrice,
    competitorPubLink,
  });
};

export const UpProductValidation = () => {
  return Yup.object().shape({
    name,
    price,
    description,
    supplierLink,
    competitorLink,
    supplierPrice,
    competitorPubLink,
  });
};

export const ReviewsValidation = () => {
  return Yup.object().shape({
    autorName,
    autorPicture,
    text,
    audioOrVideo,
  });
};

export const UpReviewValidation = () => {
  return Yup.object().shape({
    autorName,
    text,
  });
};

export const LeadPageVideoValidation = () => {
  return Yup.object().shape({
    video,
  });
};

export const LeadPageGalleryValidation = () => {
  return Yup.object().shape({
    gallery,
  });
};

export const LeadPageParagraphValidation = () => {
  return Yup.object().shape({
    paragraph,
  });
};

// function getPriceValidation() {
//   let validationObj = {};
//   for (const key in ModelRdvTimeEnum) {
//     if (Object.prototype.hasOwnProperty.call(ModelRdvTimeEnum, key)) {
//       validationObj[key] = Yup.number()
//         .min(100, "Minimum 100 dollars")
//         .required("Champs requis");
//     }
//   }
//   return validationObj;
// }

// export const rdvPricesValidation = () => {
//   return Yup.object().shape(getPriceValidation());
// };
