import React from "react";
import { MdiCardsHeart } from "../Uikits/Icons";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { useQuery } from "@tanstack/react-query";
import { getAllProducts } from "../Utils/Queries/products";
import { useNavigate } from "react-router-dom";
import { SectionLoader } from "../Uikits/Others";

function Products() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allProducts"],
    queryFn: getAllProducts,
  });
  const navigate = useNavigate();

  if (isLoading) return <SectionLoader />;
  return (
    <div className="products">
      {data.data.length == 0 ? (
        <p>Aucun produit disponible</p>
      ) : (
        <div className="pr-cards flex f-wrap">
          {data.data.map((item, i) => (
            <div
              className="pr-card-section"
              key={"product card" + i}
              onClick={() => navigate("/product/" + item._id)}
            >
              <div className="pr-card-inner">
                <section>
                  <img src={item.pictures[0].url} alt="" />
                </section>
                <section className="flex f-column">
                  <p>{item.name} </p>
                  <b>$ {item.price}</b>
                </section>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default noNeedAuth(Products);
