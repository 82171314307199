import React from "react";
import { needAuth } from "../Hocs/NeedAuth";
import { useQuery } from "@tanstack/react-query";
import { getAllFormations } from "../Utils/Queries/formations";
import useModalStore from "../Stores/Modal";
import { AddorUpdateFormationForm } from "../Components/formations/manage";
import axiosInstance from "../Utils/Axios";
import { successToast } from "../Utils/Toast";
import { BorderButton } from "../Uikits/Button";
import { SectionLoader } from "../Uikits/Others";

function ManageFormations() {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["allFormations"],
    queryFn: getAllFormations,
  });
  const showModal = useModalStore((state) => state.showModal);

  function deleteFormation(formationId) {
    axiosInstance
      .delete("/formation/delete/" + formationId)
      .then((res) => null)
      .then((err) => null)
      .finally(() => {
        refetch();
        successToast("Formations supprimer !");
      });
  }
  if (isLoading) return <SectionLoader />;

  return (
    <div className="dataManager">
      <h1>Gestions des Formations</h1>
      <BorderButton
        text="Nouvelle formations"
        onClick={() =>
          showModal(<AddorUpdateFormationForm refetch={refetch} />)
        }
      />

      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"formations nb " + i}>
              <b>{item.name}</b>
              <p>
                <strong>Prix: </strong>
                {item.price} Euros
              </p>
              <p>
                <strong>Duree: </strong>
                {item.time}
              </p>
              <p>
                <strong>Caracteristique: </strong>
                {item.details.map((item2, j) => (
                  <span key={"item " + i + "detail nb" + j}>
                    {item2} <br />
                  </span>
                ))}
              </p>
              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(
                      <AddorUpdateFormationForm
                        formation={item}
                        refetch={refetch}
                      />
                    )
                  }
                >
                  Modifier
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => deleteFormation(item._id)}
                >
                  Supprimer
                </button>
              </div>
            </article>
          ))
        ) : (
          <p>Aucune formation disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageFormations);
