import React from "react";
import { Link } from "react-router-dom";
import { MdiInstagram, MdiTelegram, MdiWhatsapp } from "./Icons";
function Footer() {
  return (
    <footer>
      <section className="footer-logoAndContactInfos flex f-column">
        <img src="/images/logoWhite.png" alt="nav logo" />
        <p>
          Les business en ligne n’ont jamais été aussi accessibles qu’à notre
          époque, l’ecommerce est aujourd’hui l’un des business les plus
          rentables et les plus faciles à mettre en place lorsque vous avez les
          bonnes informations. ECOMPLANE avec vous, pour vous et par vous
        </p>
        <a href="">
          +336 <span>32088663</span>
        </a>
        <a href="">info@ecomplane.com</a>
      </section>

      <section className="footer-fastLinks flex f-column">
        <h2>Explorer</h2>
        <div className="flex f-column">
          <Link to={"/"}>Accueil</Link>
          <Link to={"/about"}>A-propos</Link>
          <Link to={"/contact"}>Contact</Link>
          <Link to={"/faq"}>FAQ</Link>
          <Link to={"/confidentiality"}>Politique de confidentialité </Link>
        </div>
      </section>
      <section className="footer-newsLetter flex f-column">
        <h2>NewsLetter</h2>
        <p>
          Entrez votre email adresse pour vous assurez que vous ne manquez
          aucune nouvelles concernant les offres juteux du weekend
        </p>
        <form action="">
          <input type="text" placeholder="Votre email" />
          <button className="btn">S’abonner</button>
        </form>
      </section>

      <hr />

      <div className="footer-copy flex">
        <p>Copyright &copy;2024 All right reserved,ECOMPLANE</p>
        <div className="flex ai-c">
          <a
            href="https://www.instagram.com/mario_ckp/profilecard/?igsh=MTZreXNjbGwydGl5Zg=="
            target="_blank"
          >
            {" "}
            <MdiInstagram />{" "}
          </a>
          <a href="https://t.me/Mario0307" target="_blank">
            {" "}
            <MdiTelegram />{" "}
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=%2B33632088663"
            target="_blank"
          >
            {" "}
            <MdiWhatsapp />{" "}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
