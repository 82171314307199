import axiosInstance from "../../Utils/Axios";
import useModalStore from "../../Stores/Modal";
import { errorToast, successToast } from "../../Utils/Toast";
import { BorderButton } from "../../Uikits/Button";

export function AddLPReview({ refetch, video }) {
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit() {
    let request = axiosInstance.post("/leadPage/add-reviews");

    request
      .then((res) => successToast("Section ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        hideModal();
        refetch();
      });
  }
  return (
    <div>
      <BorderButton
        text={"Confirmer l'ajout de la section temoignage"}
        onClick={handleSubmit}
      />
    </div>
  );
}
