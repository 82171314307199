import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

export function NewsLetter() {
  return (
    <ScrollAnimation animateIn="fadeInUp" className="newsLetter  flex">
      <section className="nl-text">
        <h4>Abonnez vous à notre newsletter</h4>
        <p>
          Entrez votre email adresse pour vous assurez que vous ne manquez
          aucune nouvelles concernant les offres juteux du weekend
        </p>
      </section>

      <section className="nl-input">
        <form action="">
          <input type="email" placeholder="Votre email" />
          <button>S’abonner </button>
        </form>
      </section>
    </ScrollAnimation>
  );
}
