import React from "react";
import { NewsLetter } from "../Components/others";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { getOneProduct } from "../Utils/Queries/products";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { SectionLoader } from "../Uikits/Others";

function ProductDetails() {
  const { productId } = useParams();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["oneProduct"],
    queryFn: () => getOneProduct(productId),
    initialData: null,
  });

  if (isLoading) return <SectionLoader />;
  return (
    <div className="ProductsInfo ">
      {data ? (
        <>
          <h1>{data.data.name}</h1>
          <div className="flex f-wrap">
            <div className="PI-img">
              <div className="grande-image flex">
                <img src={data.data.pictures[0].url} alt="" />
              </div>
              <div className="petite-image flex ">
                <section>
                  {" "}
                  <img src={data.data.pictures[1].url} alt="" />
                </section>
                <section>
                  {" "}
                  <img src={data.data.pictures[2].url} alt="" />
                </section>
                <section>
                  {" "}
                  <img src={data.data.pictures[3].url} alt="" />
                </section>
              </div>
            </div>
            <div className="PI-text ">
              <div className="PI-text-prix flex f-column  ">
                <p>
                  <span>Identifiant produit :</span>
                  <b> {data.data.productNumber} </b>
                </p>
                <p>
                  <span>Prix d’achat (Fournisseur) :</span>
                  <b>$ {data.data.supplierPrice}</b>
                </p>
                <p>
                  <span>Prix de vente :</span>
                  <b>$ {data.data.price}</b>
                </p>
              </div>
              <div>
                <p className="">
                  <span>Lien Fournisseur:</span>
                  <a href={data.data.supplierLink} target="_blank">
                    {data.data.supplierLink}
                  </a>
                </p>
                <p>
                  <span>Lien Concurent:</span>
                  <a href={data.data.competitorLink} target="_blank">
                    {data.data.competitorLink}
                  </a>
                </p>
                <p>
                  <span>Lien Pub Concurent:</span>
                  <a href={data.data.competitorPubLink} target="_blank">
                    {data.data.competitorPubLink}
                  </a>
                </p>
                <section className="flex f-column">
                  <h2>Description:</h2>
                  <p>{data.data.description}</p>
                </section>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Aucun produit correspondant</p>
      )}
      <NewsLetter />
    </div>
  );
}

export default noNeedAuth(ProductDetails);
