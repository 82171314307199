import React, { useEffect } from "react";
import { BackgroundButton, BorderButton } from "../../Uikits/Button";
import Slider from "react-slick/lib/slider";
import ScrollAnimation from "react-animate-on-scroll";
function HomeBanner() {
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    // Simule un scroll de 1 pixel après le montage du composant
    window.scrollTo(0, 50);
  }, []);
  return (
    <div className="homeBanner flex f-wrap">
      <ScrollAnimation
        animateIn={"rotateInDownLeft"}
        className="hb-text flex f-column"
        animatePreScroll={false}
      >
        <Slider {...settings}>
          <div>
            <h1>
              Des stratégies pensées pour vous faire <br /> dépenser moins et
              <br />
              gagner plus
            </h1>
          </div>
          <div>
            <h1>
              ECOMPLANE : <br />
              Vers votre liberté <br /> financière.
            </h1>
          </div>
        </Slider>

        <div className="flex f-wrap">
          <BackgroundButton
            text="Démarrer la formation"
            isLink={true}
            link="/"
          />
          <BorderButton
            text="Contactez nous"
            isLink={true}
            link="https://t.me/Mario0307"
            target="blank"
            rel="noopener noreferrer"
          />
        </div>
        <div className="hb-AbsoluteImg">
          <img src="/images/banners/small.png" alt="absolute img" />
        </div>
      </ScrollAnimation>

      <ScrollAnimation
        animateIn={"rotateInDownRight"}
        className="hb-img"
        animatePreScroll={false}
      >
        <Slider {...settings}>
          <div className="hbi-div">
            <img src="./images/banners/banner1.webp" alt="" />
          </div>
          <div className="hbi-div">
            <img src="./images/shoots/pp5.webp" alt="" />
          </div>
        </Slider>
      </ScrollAnimation>
    </div>
  );
}

export default HomeBanner;
