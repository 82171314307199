import axiosInstance from "../Axios";

export async function getAllProducts() {
  let res = await axiosInstance.get("/product/all");
  return res;
}

export async function getOneProduct(productId) {
  let res = await axiosInstance.get("/product/" + productId);
  return res;
}
